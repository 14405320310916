import React from 'react';
import { RoccoLink } from '../../../components/link/RoccoLink';
import { useAtomValue } from 'jotai';
import { uiFooterTrackingConfig } from './Footer';

interface NavigationMenuProps {
    title: string;
    links: NavigationMenuLinkProps[];
}

interface NavigationMenuLinkProps {
    title: string;
    to: string;
    isNonRemixRoute?: boolean;
}

export const NavigationMenu = ({ title, links }: NavigationMenuProps) => {
    const trackingConfig = useAtomValue(uiFooterTrackingConfig);

    return (
        <div>
            <div className="mb-5 hidden text-base text-gray-900/50 md:block">
                {title}
            </div>
            <nav>
                <ul className="flex flex-col gap-y-2">
                    {links.map((link, index) => {
                        return (
                            <li
                                key={index}
                                className="my-1 block text-sm font-medium text-gray-900/50 md:my-0 md:text-base"
                            >
                                <RoccoLink
                                    to={link.to}
                                    onClick={() =>
                                        trackingConfig?.onNavClick(link.to)
                                    }
                                    className="md:text-gray-900 hover:text-gray-900/80"
                                    isNonRemixRoute={link.isNonRemixRoute}
                                >
                                    {link.title}
                                </RoccoLink>
                            </li>
                        );
                    })}
                </ul>
            </nav>
        </div>
    );
};
